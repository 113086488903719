import React, { useEffect, useState } from "react";
// import axiosInstance from "../../api/apiService";
// import { login } from "../../api/endoints";
import DocumentView from "./DocumentView/DocumentView";
import "./app.css";
import useBroadCastUserChannel from "../../hooks/useBroadCastUserChannel";
import { disableActions } from "../../commands/actionsCommands";
import Unauthorized from "./Unauthorized";
import { cleanWorksheetId } from "../utils/utils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
/*
  global console BroadcastChannel Excel window
*/

const App = () => {
  const [messageFromModal, setMessageFromModal] = useState("");

  const { loggedIn } = useBroadCastUserChannel();
  useEffect(() => {
    if (!loggedIn) {
      disableActions();
    }
  });
  const channel = new BroadcastChannel("office-addin-channel");
  useEffect(() => {
    let sheetDeletedEvent;

    Excel.run(async (context) => {
      const workbook = context.workbook;

      // Event listener for worksheet deletion
      sheetDeletedEvent = workbook.worksheets.onDeleted.add((eventArgs) => {
        console.log(`Sheet deleted: ${eventArgs.worksheetId}`);
        console.log(`Sheet eventArgs:    ${JSON.stringify(eventArgs)}`);
        window.storeData && delete window.storeData[cleanWorksheetId(eventArgs.worksheetId)];
        // Handle your logic here, e.g., updating UI or state
      });

      await context.sync(); // Sync the event listener
    }).catch((error) => {
      console.error(error);
    });

    // Cleanup on component unmount
    return () => {
      if (sheetDeletedEvent) {
        Excel.run(async (context) => {
          sheetDeletedEvent.remove(); // Remove the event listener
          await context.sync();
        }).catch((error) => {
          console.error(error);
        });
      }
    };
  }, []); // Empty dependency array to run this only once on component mount
  useEffect(() => {
    console.log(messageFromModal, "messageFromModal;");
  }, [messageFromModal]);
  useEffect(() => {
    channel.onmessage = (event) => {
      const message = event.data;
      if (message.type === "FROM_MODAL") {
        console.log("Message from modal:", message.payload);
        setMessageFromModal(message.payload);
      }
    };

    return () => {
      channel.close();
    };
  }, [channel]);

  return (
    <div className="app-wrapper">
      {loggedIn ? (
        <DocumentView />
      ) : (
        <div className="not_authorized">
          <Unauthorized />
        </div>
      )}
    </div>
  );
};

export default App;
