import React, { useState } from "react";
import { Radio, RadioGroup } from "@fluentui/react-components";
import { InfoFilled } from "@fluentui/react-icons";
import "./confirm.style.scss";

// eslint-disable-next-line react/prop-types
const Confirm = ({ setSheetType, cancel }) => {
  const [value, setValue] = useState("new");
  return (
    <div className="confirm_container">
      <div className="confirm_title">Choose Where to Place Ontology Fields</div>
      <div className="confirm_body">
        <RadioGroup value={value} onChange={(_, data) => setValue(data.value)}>
          <Radio value="new" label="New Worksheet" />
          <div className="existing_container">
            <Radio value="existing" label="Existing Worksheet" />
            <div className="icon_container">
              <InfoFilled color={"#FAC614"} className="info_icon" />
              <img src="/assets/triangle.svg" className="triangle" />
            </div>
          </div>
          <div className="confirm_note">
            This will permanently delete any existing data in the sheet and it cannot be recovered.
          </div>
        </RadioGroup>
      </div>
      <div className="confirm_button_container">
        <button
          className="btn contained confirm_button_ok"
          onClick={() => {
            setSheetType(value);
          }}
        >
          Ok
        </button>
        <button className="btn outlined confirm_button_cancel" onClick={() => cancel()}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Confirm;
