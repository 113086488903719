/* eslint-disable react/prop-types */
import React from "react";
const BoundingBox = ({
  onClick = () => undefined,
  onDoubleClick = () => undefined,
  className,
  left,
  top,
  height,
  width,
  active,
}) => {
  return (
    <div
      className={className}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      style={{
        position: "absolute",
        left,
        top,
        width,
        height,
        border: active ? "1px solid #0F7C41" : "1px solid #118EEA",
        background: active ? "rgba(15, 124, 65, 0.15)" : "rgba(190, 207, 220,0.15)",
        zIndex: 1,
        cursor: "pointer",
      }}
    />
  );
};
export default BoundingBox;
