import React from "react";
import "./unauthorized.style.scss";
/* global window */
const Unauthorized = () => {
  const onButtonClick = () => {
    window.userHandler();
  };
  return (
    <div className="unauthorized_view">
      <div className="unauthorized_container">
        <img src="/assets/images/svg/cognaize-logo-big-white.svg" alt="" title="" />
        <div className="unauthorized_text_block">
          <p className="title_text">What can you do here?</p>
          <p className="regular_text">
            Extract meaningful data from your documents with ease and precision, directly within your Excel workbooks.
          </p>
        </div>
        <div className="unauthorized_footer">
          <p>To access all features of Cognaize Excel, please login to continue.</p>
          <button className="btn contained" onClick={onButtonClick}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
