const userChannel = new BroadcastChannel("user-channel");

import { useEffect, useState } from "react";
/* global  BroadcastChannel  localStorage*/
const useBroadCastUserChannel = (props) => {
  const { onLogout, onLogin } = props || {};
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("accessToken"));
  useEffect(() => {
    userChannel.onmessage = (event) => {
      const message = event.data;
      if (message === "LOGOUT") {
        setLoggedIn(false);
        onLogout && onLogout();
      }
      if (message === "LOGIN") {
        setLoggedIn(true);
        onLogin && onLogin();
      }
    };
    // Cleanup when component unmounts
    return () => {
      userChannel.close();
    };
  }, []);
  return {
    channel: userChannel,
    loggedIn,
  };
};
export default useBroadCastUserChannel;
