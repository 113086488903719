import axios from "axios";
import dayjs from "dayjs";
import axiosInstance from "./apiService";

/*global FormData*/

export const uploadFile = async ({ file, pageCount, addFileInFiles }) => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  const props = {
    fileName: file.name,
    fileType: "PDF",
    size: file.size,
    pageCount: pageCount,
  };
  const preSignedUrlForUpload = await axiosInstance.post(`/upload/init`, props);
  if (preSignedUrlForUpload.error) return preSignedUrlForUpload;
  const { data } = preSignedUrlForUpload.data;
  const item = data;

  const initialDataBeforeGet = {
    creationDate: dayjs().toString(),
    status: "PENDING",
    preSignUrl: "string",
    id: "",
    name: file.name,
    pageCount: pageCount,
    size: file.size,
    fileDetailId: item.fileId,
    creator: "",
    path: "",
    scope: "",
    scopeId: "",
  };
  addFileInFiles(initialDataBeforeGet);
  const uploadByPressignUrl = await axios.put(item.presignedUrl, bodyFormData, {
    headers: {
      "x-ms-blob-type": "BlockBlob",
      "Content-Type": "application/pdf",
    },
  });
  const completeRequest = await axiosInstance.post(`/upload/complete`, {
    fileId: item.fileId,
    status: uploadByPressignUrl.status === 200 || uploadByPressignUrl.status === 201 ? "SUCCESS" : "FAIL",
    metadata: [],
  });

  return completeRequest;
};

export const getFiles = async ({ pageNumber = 0, pageSize = 10 }) => {
  const { data } = await axiosInstance.post(`/documents/by-type`, {
    classificationType: "agent-bank-notice",
    pageNumber: pageNumber,
    pageSize,
    mimeType: ["application/pdf"],
  });
  return data;
};

export const getFileImage = async ({ startInterval, endInterval, fileId }) => {
  const { data } = await axiosInstance.get(
    `/artifacts/${fileId}?artifactDefinitionId=cba5d5d4-45ef-11ef-8ab0-0242ac130002&dataTransformerId=cba5b95c-45ef-11ef-8ab0-0242ac130888&indices=${startInterval}-${endInterval}`
  );
  return data;
};

export const getFileOcr = async ({ startInterval, endInterval, fileId }) => {
  const { data } = await axiosInstance.get(
    `/artifacts/${fileId}?artifactDefinitionId=cba5ebb5-45ef-11ef-8ab0-0242ac130002&dataTransformerId=cba5b95c-45ef-11ef-8ab0-0242ac130888&indices=${startInterval}-${endInterval}`
  );
  return data;
};

export const getFile = async (scopeId) => {
  const { data } = await axiosInstance.get(`/internal/file-details/by-id/${scopeId}`);
  return data.data;
};

export const getApplication = async (scopeId) => {
  try {
    const { data } = await axiosInstance.get(`/document-data/${scopeId}`, {
      params: {
        ontologyIri: encodeURI("http://www.cognaize.com/ontologies/Notices/AgentBank/"),
      },
    });
    return data.data;
  } catch (error) {
    return error;
  }
};

export const getOntologies = async () => {
  const { data } = await axiosInstance.get(`/ontologies/all`);
  return data.data;
};

export const getOntology = async (name) => {
  const { data } = await axiosInstance.get(`/ontologies?name=${name}`);
  return data.data;
};
