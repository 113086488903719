/* eslint-disable react/prop-types */
import React from "react";
import dayjs from "dayjs";
import { DocumentCopy24Regular } from "@fluentui/react-icons";

/*global navigator console Excel*/
// const channel = new BroadcastChannel("office-addin-channel");

const ListItem = (props) => {
  const { columns, file } = props;
  const handleCopyClick = (e) => {
    e.stopPropagation();
    Excel.run(async (context) => {
      context.workbook.worksheets.getActiveWorksheet();
      const cell = context.workbook.getSelectedRange();
      cell.format.autofitColumns();
      cell.format.wrapText = true;
      cell.values = [[file.scopeId]];
      context.sync();
    });
    function copyToClipboard(text) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            console.log("Text copied to clipboard successfully!");
          })
          .catch((err) => {
            console.error("Failed to copy text: ", err);
          });
      }
    }
    copyToClipboard(file.scopeId);
  };
  return (
    <>
      {columns.map((column) => {
        return column.key === "uploadDate" ? (
          <div key={file.id + column.key} className="table_cell_content">
            {column.key in file ? dayjs(file[column.key]).format("D/MM/YYYY") : column.value}
          </div>
        ) : (
          <div key={file.id + column.key} className="table_cell_content">
            {column.key in file ? file[column.key] : column.value}
          </div>
        );
      })}
      <div key={file.id + "copy"} className="table_cell_action" onClick={handleCopyClick}>
        <DocumentCopy24Regular />
      </div>
    </>
  );
};

export default ListItem;
