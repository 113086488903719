import React, { useCallback, useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import PropTypes from "prop-types";

// import { useParams } from "react-router-dom";

// import { useGetDocumentQuery } from "../../../store/files/filesApi";

import MsgViewer from "./MSGViewer";
import PdfViewer from "./PdfViewer";
import TIFFViewer from "./TiffViewer";
import TxtViewer from "./TxtViewer";
import { getApplication, getFile } from "../../../api/endoints";
import { insertOntologies } from "../../taskpane";
import { enabledReopen, enabledSaveFinish } from "../../../commands/actionsCommands";
/*global console  indexedDB localStorage*/

const DocumentsViewer = (props) => {
  const { id, sheetType } = props;

  const [fileData, setFileData] = useState("");
  const [isLoading, setFilesLoading] = useState();

  useEffect(() => {
    getFile(id)
      .then(async (response) => {
        const files = response;
        const status = response.status;

        if (status === "Approved" || status === "Approval in Progress") {
          await enabledSaveFinish(false);
          await enabledReopen();
        } else {
          await enabledSaveFinish(true);
        }
        setFileData(files);

        setFilesLoading(false);
      })
      .catch(() => {
        setFilesLoading(false);
      });
    setFilesLoading(true);
  }, [id]);

  useEffect(() => {
    const scopeId = localStorage.getItem("scopeId");
    if (scopeId !== id) {
      getApplication(id).then((response) => {
        const application = response;

        const request = indexedDB.open("ontologyDatabase", 1);

        request.onupgradeneeded = function (event) {
          const db = event.target.result;
          // 2. Create an object store if it doesn't exist
          if (!db.objectStoreNames.contains("ontologyObjectStore")) {
            db.createObjectStore("ontologyObjectStore", { keyPath: "id" });
          }
        };
        request.onsuccess = function (event) {
          const db = event.target.result;

          // 3. Start a transaction to perform read/write operations
          const transaction = db.transaction("ontologyObjectStore", "readwrite");
          const objectStore = transaction.objectStore("ontologyObjectStore");
          objectStore.clear();

          // 5. Store the object
          localStorage.setItem("scopeId", id);
          const storeRequest = objectStore.add({ id, application });

          storeRequest.onsuccess = function () {
            console.log("Object added successfully");
          };

          storeRequest.onerror = function () {
            console.log("Error adding object");
          };
        };

        const header = application.properties?.map(({ label }) => label);
        const headerValueId = application.properties[1].id;
        if (headerValueId) {
          const ontology = application.fields?.map(({ label, children }) => [
            ...children.map((item) => {
              const { label, values } = item;
              return { ...item, groupValue: [label, values[headerValueId][0].value] };
            }),
            { groupValue: [label, ""] },
          ]);
          insertOntologies({ ontology, header, sheetType, headerValueId });
        }
      });
    }
  }, [id]);

  const renderFile = useCallback(() => {
    const { mimeType, preSignUrl } = fileData || {};

    if (!mimeType || !preSignUrl) return null;
    switch (mimeType) {
      case "image/tiff":
      case "image/tif": {
        return <TIFFViewer tiff={preSignUrl} />;
      }
      case "application/pdf": {
        return <PdfViewer src={preSignUrl} />;
      }
      case "text/plain": {
        return <TxtViewer src={preSignUrl} />;
      }
      case "application/vnd.ms-outlook": {
        return <MsgViewer src={preSignUrl} />;
      }

      default: {
        return (
          <div
            style={{
              height: "100%",
              "#react-doc-viewer": {
                height: "100%",
                "#proxy-renderer": {
                  overflow: "hidden",
                },
                "#html-renderer": {
                  padding: 0,
                  "#html-body": {
                    padding: 0,
                    border: "none",
                  },
                },
              },
            }}
          >
            <DocViewer
              documents={[{ uri: preSignUrl }]}
              pluginRenderers={DocViewerRenderers}
              config={{
                header: {
                  disableHeader: true,
                },
              }}
            />
          </div>
        );
      }
    }
  }, [fileData]);

  return (
    <>{isLoading ? <div>Loading...</div> : fileData && <div style={{ position: "relative" }}>{renderFile()}</div>}</>
  );
};

DocumentsViewer.propTypes = {
  id: PropTypes.string,
  sheetType: PropTypes.string,
};

export default DocumentsViewer;
