import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/*global console fetch*/

const TxtViewer = ({ src }) => {
  const [txt, setTxt] = useState("");
  useEffect(() => {
    const loadText = async () => {
      try {
        const response = await fetch(src);
        const responseText = await response.text();
        setTxt(responseText);
      } catch (error) {
        console.log(error, "error");
      }
    };
    loadText();
  }, [src]);
  return (
    <div
      style={{
        whiteSpace: "pre-wrap",
        maxHeight: "100%",
        overflow: "auto",
        paddingTop: 5,
        paddingBottom: 5,
      }}
      dangerouslySetInnerHTML={{ __html: txt }}
    />
  );
};
TxtViewer.propTypes = {
  src: PropTypes.string,
};

export default TxtViewer;
