/* global Office console setTimeout window process */
let dialog = null;
const isDev = process.env.NODE_ENV === "development";
const APP_HOST = process.env.APP_HOST;
function closeDialog() {
  if (dialog) {
    dialog?.close();
    dialog = null;
    console.info("Dialog closed via closeDialog function.");
  }
}
window.closeUserDialog = closeDialog;
function openFullScreenUserModal(/* action */) {
  console.info(dialog, "Attempting to open modal...");
  if (dialog) {
    closeDialog();
  }
  // Small timeout to ensure dialog cleanup before opening a new one
  setTimeout(() => {
    Office.context.ui.displayDialogAsync(
      isDev ? "https://localhost:3000/user.html" : `${APP_HOST}/user.html`,
      { height: 50, width: 40, displayInIframe: true },
      function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          dialog = asyncResult.value;
          console.info("Dialog opened successfully.");

          // Handle dialog events
          dialog?.addEventHandler(Office.EventType.DialogMessageReceived, async (arg) => {
            const msg = JSON.parse(arg.message);
            if (msg.type === "CLOSE_USER_DIALOG") {
              closeDialog();
            }
            if (msg.type === "LOGIN_SUCCESS") {
              /* if (window[action]) {
                console.log(action, "action");
                await closeDialog();
                window[action]();
              } */
            }
          });

          dialog?.addEventHandler(Office.EventType.DialogEventReceived, (arg) => {
            console.info("Dialog from user event received:", arg.error);

            if (arg.error === 12006) {
              // Dialog closed by user
              //   channel.postMessage({ type: "FROM_MODAL", payload: "Bye from Modal 2" });

              //   setTimeout(() => {
              //     channel.close();
              //   });
              dialog = null;
            } else {
              console.info("Dialog event error:", arg.error);
            }
          });
        } else {
          console.error("Failed to open dialog:", asyncResult.error.message);
        }
      }
    );
  }, 200); // Adjust the timeout duration as needed
}

Office.onReady((info) => {
  if (info.host === Office.HostType.Excel) {
    console.info("Excel is ready.");
    window.userHandler = (event, action) => {
      console.info(action, "Custom function executed.");
      try {
        openFullScreenUserModal(action);
      } catch (error) {
        console.info("Error in userHandler:", error);
      }
      event?.completed();
    };
  }
});
