import React, { useEffect, useState } from "react";

import MSGReader from "msgreader";

/*global  fetch console*/

// eslint-disable-next-line react/prop-types
const MsgViewer = ({ src }) => {
  const [emailData, setEmailData] = useState();

  useEffect(() => {
    // Fetch the .msg file from the URL
    const fetchMsgFile = async () => {
      try {
        const response = await fetch(src);
        const arrayBuffer = await response.arrayBuffer();
        const reader = new MSGReader(arrayBuffer);

        // Get file data
        const fileData = reader.getFileData();
        // Extract the necessary information
        setEmailData({
          subject: fileData.subject,
          senderName: fileData.senderName,
          senderEmail: fileData.senderEmail,
          recipients: fileData.recipients,
          body: fileData.body,
        });
      } catch (error) {
        console.error("Error fetching or parsing the .msg file:", error);
      }
    };

    fetchMsgFile();
  }, [src]);

  // Display the parsed email data
  return (
    <div style={{ whiteSpace: "pre-wrap", overflow: "auto", height: "100%", pt: 5 }}>
      {emailData ? (
        <>
          <h1>{emailData.subject}</h1>
          <p>
            <strong>From:</strong> {emailData.senderName} ({emailData.senderEmail})
          </p>
          <p>
            <strong>To:</strong> {emailData.recipients.map((r) => r.name).join(", ")}
          </p>
          <div dangerouslySetInnerHTML={{ __html: emailData.body }} />
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default MsgViewer;
