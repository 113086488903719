import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import UTIF from "utif";
/*global document fetch console*/

import DocumentsViewerFooter from "../DocumentsViewerFooter";

import "./TiffViewer.style.scss";

const TIFFViewer = forwardRef(function TiffFileViewer(
  // eslint-disable-next-line react/prop-types
  { tiff },
  ref
) {
  const [_tiff] = useState(tiff);
  const [, setTiffs] = useState([]);
  const [pages, setPages] = useState([]);

  const canvasRef = useRef(null);

  const imgLoaded = (e) => {
    const ifds = UTIF.decode(e.target.response);
    const _tiffs = ifds.map(function (ifd, index) {
      UTIF.decodeImage(e.target.response, ifd);
      const rgba = UTIF.toRGBA8(ifd);
      const canvas = document.createElement("canvas");

      canvas.width = ifd.width;
      canvas.height = ifd.height;
      const ctx = canvas.getContext("2d");
      const img = ctx.createImageData(ifd.width, ifd.height);
      img.data.set(rgba);
      ctx.putImageData(img, 0, 0);

      if (index === 0) document.getElementById("tiff-inner-container").appendChild(canvas);
      return canvas;
    });
    setPages(_tiffs);
    setTiffs(_tiffs);
  };

  useEffect(() => {
    async function displayTIFF(tiffUrl) {
      try {
        const response = await fetch(tiffUrl);
        const arrayBuffer = await response.arrayBuffer();
        imgLoaded({ target: { response: arrayBuffer } });
      } catch (error) {
        console.log(error);
      }
    }
    displayTIFF(_tiff);
  }, [_tiff]);

  useEffect(() => {
    if (pages.length > 0) {
      if (canvasRef.current) {
        canvasRef.current.innerHTML = "";
        canvasRef.current.appendChild(pages[0]);
      }
    }
  }, [pages]);

  // ref all page print
  useImperativeHandle(ref, () => ({
    context: () => {
      pages.forEach((page, index) => {
        if (index > 0 && canvasRef.current) {
          canvasRef.current.style.display = "block";
          canvasRef.current.appendChild(page);
        }
      });
      return canvasRef.current;
    },
  }));
  const onPageChanged = (page) => {
    if (canvasRef.current) {
      canvasRef.current.innerHTML = "";
      canvasRef.current.appendChild(pages[page - 1]);
    }
  };
  console.log(pages, "pages");
  return (
    <>
      <div className={"stylescontainer TIFFViewer"} id="tiff-container">
        <div id="tiff-inner-container" className={"styles.inner TIFFInnerContainer"} ref={canvasRef} />
      </div>
      <DocumentsViewerFooter pageCount={pages.length} onPageChanged={onPageChanged} />
    </>
  );
});

export default TIFFViewer;
