import { useCallback, useEffect, useState } from "react";
/* global*/
const defaultCoordinates = {
  startPointTop: 0,
  startPointLeft: 0,
  top: 0,
  left: 0,
  width: 0,
  height: 0,
};
const useTagDrawer = (props) => {
  const {
    container,
    onDrawEnd = () => undefined,
    onDrawStart = () => undefined,
    coordinates: propsCoordinates = null,
    drawingEnabled: propsDrawingEnabled = false,
    disabled = false,
  } = props;
  const [drawingEnabled, setDrawingEnabled] = useState(propsDrawingEnabled);
  const [coordinates, setCoordinates] = useState(propsCoordinates ? propsCoordinates : defaultCoordinates);
  const coordinatesParser = useCallback(() => {
    const x_min = coordinates.left > coordinates.startPointLeft ? coordinates.startPointLeft : coordinates.left;
    const y_min = coordinates.top > coordinates.startPointTop ? coordinates.startPointTop : coordinates.top;
    const x_max = x_min + coordinates.width;
    const y_max = y_min + coordinates.height;
    const containerSizes = container?.getBoundingClientRect();
    const x_min_percent = (x_min * 100) / containerSizes?.width;
    const y_min_percent = (y_min * 100) / containerSizes?.height;

    const x_max_percent = (x_max * 100) / containerSizes?.width;
    const y_max_percent = (y_max * 100) / containerSizes?.height;
    return {
      x_min_percent,
      x_max_percent,
      y_max_percent,
      y_min_percent,
      y_min,
      x_min,
      x_max,
      y_max,
      height: coordinates.height,
      width: coordinates.width,
      reset: () => {
        setCoordinates(defaultCoordinates);
      },
    };
  }, [coordinates]);
  useEffect(() => {
    const mouseDownHandler = (e) => {
      if (!drawingEnabled) {
        const rect = container?.getBoundingClientRect();
        const xCoordinate = e.clientX - rect.left;
        const yCoordinate = e.clientY - rect.top;
        setCoordinates({
          ...coordinates,
          startPointTop: yCoordinate,
          startPointLeft: xCoordinate,
        });
        setDrawingEnabled(true);
        onDrawStart();
      }
    };
    const moveHandler = (e) => {
      if (drawingEnabled) {
        const { startPointTop, startPointLeft } = coordinates;
        const rect = container?.getBoundingClientRect();
        const xCoordinate = e.clientX - rect.left;
        const yCoordinate = e.clientY - rect.top;
        setCoordinates({
          ...coordinates,
          top: yCoordinate,
          left: xCoordinate,

          height: yCoordinate > startPointTop ? yCoordinate - startPointTop : startPointTop - yCoordinate,
          width: xCoordinate > startPointLeft ? xCoordinate - startPointLeft : startPointLeft - xCoordinate,
        });
      }
    };

    const mouseUpHandler = () => {
      onDrawEnd(coordinatesParser());
      setDrawingEnabled(false);
      setCoordinates(defaultCoordinates);
    };
    if (!disabled) {
      container?.addEventListener("mousedown", mouseDownHandler);
      if (drawingEnabled) {
        container?.addEventListener("mousemove", moveHandler);
        container?.addEventListener("mouseup", mouseUpHandler);
      }
    }

    return () => {
      container?.removeEventListener("mousedown", mouseDownHandler);
      container?.removeEventListener("mousemove", moveHandler);
      container?.removeEventListener("mouseup", mouseUpHandler);
    };
  }, [container, drawingEnabled, coordinatesParser, coordinates, onDrawEnd, onDrawStart, disabled]);

  return { ...coordinatesParser() };
};

export default useTagDrawer;
