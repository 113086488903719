/* global localStorage */
export const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const getLetterByIndex = (index) => {
  if (index >= 1 && index <= 26) {
    return alphabet[index - 1];
  } else {
    return "Invalid index";
  }
};
export const getLetterIndex = (letter) => {
  return alphabet.indexOf(letter.toUpperCase()) + 1;
};

export const getScopeIdFromStorage = () => {
  return localStorage.getItem("scopeId");
};
