import React, { useCallback, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import DocumentsViewerFooter from "../DocumentsViewerFooter";
import "./PdfViewer.style.scss";
import { cleanWorksheetId } from "../../../utils/utils";
import useTagDrawer from "../../../../hooks/useBoundingBoxDrawer";
import BoundingBox from "./BoundingBox";
const lineageChannel = new BroadcastChannel("lineage");

/*global console Excel window document BroadcastChannel setTimeout*/

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// eslint-disable-next-line react/prop-types
const PdfViewer = ({ src }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [activeSheet, setActiveSheet] = useState("");
  const [currentData, setCurrentData] = useState({});
  const [activeCellCoords, setActiveCellCoords] = useState(null);
  const containerRef = useRef(null);
  const drawerData = useTagDrawer({
    container: containerRef.current,
    onDrawEnd: (data) => {
      const { x_min_percent, y_min_percent, x_max_percent, y_max_percent } = data;
      if (x_min_percent && y_min_percent && x_max_percent && y_max_percent) {
        Excel.run(async (context) => {
          const selectedRange = context.workbook.getSelectedRange();
          selectedRange.clear();
          await context.sync();
          selectedRange.formulas = [
            [`={${pageNumber}, ${x_min_percent},${y_min_percent},${x_max_percent},${y_max_percent}}`],
          ];
          await context.sync();
          data.reset();
        }).catch((error) => {
          console.log(error);
        });
      }
    },
  });
  const renderData = useCallback(() => {
    const data = [];
    Object.entries(currentData).forEach(([key, value]) => {
      if (value.lineage && value.lineage.page_number === pageNumber) {
        data.push(
          <BoundingBox
            key={value.id}
            className={`cell-${key}`}
            active={key === activeCellCoords}
            onClick={async () => {
              await Excel.run(async (context) => {
                const sheet = context.workbook.worksheets.getActiveWorksheet();
                const range = sheet.getRange(key); // Change the range address as needed.
                range.select(); // This will activate the cell.
                await context.sync();
              });
              setActiveCellCoords(key);
            }}
            onDoubleClick={() => {
              if (window.storeData[activeSheet]) {
                const data = window.storeData[activeSheet][key];
                delete data.lineage;
                const newData = {
                  ...(window.storeData[activeSheet] || {}),
                  [key]: {
                    ...data,
                  },
                };
                window.storeData[activeSheet] = newData;
              }
            }}
            left={value.lineage.bounding_box[0] + "%"}
            top={value.lineage.bounding_box[1] + "%"}
            width={value.lineage.bounding_box[2] - value.lineage.bounding_box[0] + "%"}
            height={value.lineage.bounding_box[3] - value.lineage.bounding_box[1] + "%"}
          />
        );
      }
    });
    return data;
  }, [currentData, pageNumber, activeCellCoords, activeSheet]);
  useEffect(() => {
    lineageChannel.onmessage = ({ data: message }) => {
      if (message.type === "LINEAGE_CREATED") {
        const { data } = message;
        setActiveCellCoords(data.cellCoordinate);
        setPageNumber(data.lineage.page_number);
      }
    };
    return () => {
      lineageChannel.close();
    };
  }, []);
  useEffect(() => {
    let eventHandler;
    Excel.run(async (context) => {
      const worksheet = context.workbook.worksheets.getActiveWorksheet();
      eventHandler = worksheet.onSingleClicked.add(handleSingleClick);

      console.log("Single click event listener added.");
    });

    function handleSingleClick(event) {
      console.log(`Cell clicked at address: ${event.address}`);
      const workSheetData = window.storeData[activeSheet];
      if (workSheetData && workSheetData[event.address]) {
        const newPage = workSheetData[event.address].lineage.page_number;
        console.log(workSheetData[event.address], "workSheetData[event.address]");
        setPageNumber(newPage);
        setActiveCellCoords(event.address);
      } else {
        setActiveCellCoords(null);
      }
    }
    return () => {
      Excel.run(async (context) => {
        if (eventHandler) {
          // Remove the event listener
          eventHandler.remove();
          await context.sync();
          console.log("Single click event listener removed.");
        } else {
          console.log("No event listener to remove.");
        }
      });
    };
  }, [activeSheet]);
  useEffect(() => {
    const removeListener = window.storeData.addListener((prop, oldValue, newValue) => {
      window.storeData[activeSheet] && setCurrentData(newValue);
    });

    return () => {
      removeListener;
    };
  }, [activeSheet]);
  useEffect(() => {
    if (window.storeData && window.storeData[activeSheet]) {
      setCurrentData(window.storeData[activeSheet]);
    } else {
      setCurrentData({});
    }
  }, [activeSheet]);
  useEffect(() => {
    const getActiveSheetName = async () => {
      try {
        await Excel.run(async (context) => {
          const activeSheet = context.workbook.worksheets.getActiveWorksheet();

          activeSheet.load("id");
          await context.sync(); // Sync the workbook state

          setActiveSheet(cleanWorksheetId(activeSheet.id));
        });
      } catch (error) {
        console.error("Error getting active sheet name: ", error);
      }
    };
    getActiveSheetName();
  }, []);
  useEffect(() => {
    let selectionEvent;

    Excel.run(async (context) => {
      const workbook = context.workbook;

      // Event listener for selection change
      selectionEvent = workbook.onSelectionChanged.add(async (eventArgs) => {
        const activeSheet = eventArgs.workbook.worksheets.getActiveWorksheet();
        activeSheet.load("id");
        await context.sync();

        console.log(`Current active sheet: ${activeSheet.id}`);

        setActiveSheet(cleanWorksheetId(activeSheet.id));
      });

      await context.sync(); // Sync the event listener
    }).catch((error) => {
      console.error(error);
    });

    // Cleanup on component unmount
    return () => {
      if (selectionEvent) {
        Excel.run(async (context) => {
          selectionEvent.remove(); // Remove the event listener
          await context.sync();
        }).catch((error) => {
          console.error(error);
        });
      }
    };
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const onPageChanged = (page) => {
    setPageNumber(page);
  };
  const onScaleChanged = (newScale) => {
    setScale(newScale);
  };
  const { x_min, y_min, height, width } = drawerData;
  return (
    <>
      <div>
        <Document
          file={src}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => console.log("Error while loading document! " + error.message)}
        >
          <div className="PDFViewerContainer">
            <div ref={containerRef} className="PDFViewerPageContainer">
              {renderData()}
              {!!(x_min && y_min && height && width) && (
                <BoundingBox key={"drawer_box"} left={x_min} top={y_min} height={height} width={width} />
              )}
              <Page
                onRenderSuccess={() => {
                  if (activeCellCoords) {
                    setTimeout(() => {
                      const element = document.querySelector(`.cell-${activeCellCoords}`);
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
                      }
                    });
                  }
                }}
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                scale={scale}
              />
            </div>
          </div>
        </Document>
      </div>

      <DocumentsViewerFooter
        scaleEnabled={true}
        pageCount={numPages}
        onPageChanged={onPageChanged}
        onScaleChanged={onScaleChanged}
        scale={scale}
        pageNumber={pageNumber}
      />
    </>
  );
};

export default PdfViewer;
