/* eslint-disable react/prop-types */
import React, { useMemo, useRef } from "react";
import {
  ArrowLeftFilled,
  ArrowRightFilled,
  AddFilled,
  SubtractFilled,
  // ArrowMinimizeRegular,
} from "@fluentui/react-icons";
import Select from "react-select";
import { components } from "react-select";

import "./DocumentsViewerFooter.style.scss";
/*global */
const options = [
  { value: 0.5, label: "50%" },
  { value: 0.75, label: "75%" },
  { value: 0.9, label: "90%" },
  { value: 1, label: "100%" },
  { value: 1.25, label: "125%" },
  { value: 1.5, label: "150%" },
  { value: 2, label: "200%" },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#191571",
    borderColor: "#191571",
    color: "white",
    boxShadow: "none",
    borderRadius: "0px",
    height: "30px",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      borderColor: "none",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
    height: "30px",
    fontSize: "14px",
    minHeight: "30px",
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "white" : "#191571",
    color: state.isSelected ? "black" : "white",
    cursor: "pointer",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "tran",
    color: "none",
  }),
  indicatorSeparator: () => ({
    display: "none", // Hide the indicator separator
  }),
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span style={{ fontSize: "10px", color: "#fff", lineHeight: "15px", height: "25px" }}>▼</span>{" "}
      {/* Custom arrow (can replace with SVG or other icons) */}
    </components.DropdownIndicator>
  );
};

const CustomInput = (props) => (
  <components.Input {...props} readOnly /> // Make the input read-only
);

const DocumentsViewerFooter = ({
  pageCount,
  onPageChanged,
  scaleEnabled = false,
  scale = 1,
  onScaleChanged,
  pageNumber,
}) => {
  const currentPageRef = useRef(null);

  const handlePageChange = (newPage) => {
    onPageChanged(newPage);
  };

  const handleScaleChange = (value) => {
    let newScale = scale + value;
    if (newScale < -10) {
      newScale = 1;
    }
    if (newScale > 12) {
      newScale = 12;
    }
    onScaleChanged && onScaleChanged(newScale);
  };

  const pageManualChange = (e) => {
    const page = Number(e?.target?.value);
    if (page > pageCount || page < 0 || page % 1 !== 0 || e?.target?.value === "0") return;
    if (page) {
      onPageChanged(page);
      currentPageRef.current = page;
    }
  };

  const selectedValue = useMemo(() => {
    const valuesArray = options.map((item) => item.value);

    if (valuesArray.includes(scale)) {
      return options.find((el) => el.value === scale);
    } else {
      return options.find((el) => el.value === 1);
    }
  }, [scale]);

  return (
    <div className="documents_viewer_footer">
      <ArrowLeftFilled
        className={pageNumber <= 1 ? "disabled_icon" : ""}
        onClick={() => {
          if (pageNumber > 1) {
            const newPage = pageNumber - 1;
            handlePageChange(newPage);
          }
        }}
        style={{
          padding: 0,
          color: "#ffffff",
          fontSize: "18px",
          cursor: "pointer",
        }}
      />
      <input
        ref={currentPageRef}
        className="page_input"
        value={pageNumber}
        type="text"
        onChange={pageManualChange}
        min={1}
        max={pageCount}
      />
      <div className="page_count">
        <p style={{ margin: "0 10px", display: "flex", alignItems: "center" }}>/ {pageCount}</p>
      </div>
      <ArrowRightFilled
        onClick={() => {
          if (pageNumber < pageCount) {
            const newPage = pageNumber + 1;
            handlePageChange(newPage);
          }
        }}
        style={{
          padding: 0,
          color: "#ffffff",
          fontSize: "18px",
          cursor: "pointer",
        }}
        className={pageNumber === pageCount ? "disabledIcon" : ""}
      />
      {scaleEnabled && (
        <div
          style={{
            display: "flex",
            gap: 3,
            marginRight: 4,
            marginLeft: 4,
          }}
        >
          <SubtractFilled
            disabled={scale <= 0.3}
            onClick={() => handleScaleChange(-(5 / 100))}
            style={{
              padding: 0,
              color: "#ffffff",
              fontSize: "18px",
              cursor: "pointer",
              marginTop: "7px",
            }}
          />

          <Select
            options={options}
            menuPlacement="top"
            className="footer_dropdown"
            styles={customStyles}
            defaultValue={selectedValue}
            onChange={({ value }) => onScaleChanged && onScaleChanged(value)}
            components={{ DropdownIndicator, Input: CustomInput }}
          />
          {/* <ArrowMinimizeRegular
            disabled={scale === 0.5}
            onClick={() => onScaleChanged && onScaleChanged(0.5)}
            style={{
              padding: 0,
              color: "#ffffff",
              fontSize: "14px",
              cursor: "pointer",
            }}
          /> */}
          <AddFilled
            disabled={scale === 12}
            onClick={() => handleScaleChange(5 / 100)}
            style={{
              padding: 0,
              color: "#ffffff",
              fontSize: "18px",
              cursor: "pointer",
              marginTop: "7px",
            }}
          />
        </div>
      )}
    </div>
  );
};
export default DocumentsViewerFooter;
