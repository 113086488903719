import React, { useEffect, useState } from "react";

import { getFiles } from "../../../api/endoints";
import "react-responsive-pagination/themes/minimal.css";

import "./documentView.style.scss";

import DocumentsList from "./DocumenstList/List";
import DocumentsViewer from "../DocumentsViewer";
import usePrevious from "../../hooks/usePreviouse";
import LayerLoading from "../../../reusableComponents/LayerLoading";
import Confirm from "../Confirm/Confirm";
import CustomPagination from "../CustomPagination/CustomPagination";
/*global localStorage*/

const DocumentView = () => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [sheetType, setSheetType] = useState();

  const [filesData, setFilesData] = useState([]);

  const [filesLoading, setFilesLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [dataInfo, setDataInfo] = useState({ totalElements: 0, totalPages: 0 });
  const prevLoading = usePrevious(filesLoading);

  useEffect(() => {
    setFilesLoading(true);
    getFiles({ pageNumber: currentPage - 1, pageSize })
      .then((response) => {
        const { content, totalElements, totalPages } = response.data;
        setFilesData(content);
        setDataInfo({ totalElements, totalPages });
        setFilesLoading(false);
      })
      .catch(() => {
        setFilesLoading(false);
      });
  }, [currentPage, pageSize]);

  if (filesLoading) {
    return <LayerLoading size="lg" />;
  }
  const scopeId = localStorage.getItem("scopeId");
  return (
    <div className="pdf-view-wrapper">
      {prevLoading && !filesLoading && !filesData.length && <div className="pdf-view-center">No Files</div>}
      {filesData.length > 0 && !selectedFile && (
        <>
          <DocumentsList
            loading={false}
            files={filesData}
            selectFile={(id) => {
              setSelectedFile(id);
              if (scopeId !== id) {
                setOpenConfirm(true);
              }
            }}
          />
          <CustomPagination
            pageNumber={currentPage}
            pageCount={dataInfo.totalPages}
            onPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
        </>
      )}
      {selectedFile && !openConfirm && (sheetType || scopeId === selectedFile) && (
        <>
          <div className="breadcrumb-header">
            <div className="breadcrumb">
              <img src="/assets/ArrowBackRounded.svg" />
              <button
                onClick={() => {
                  setSelectedFile("");
                  setSheetType("");
                }}
              >
                Back
              </button>
              <div className="breadcrumb-arrow ">{"|"}</div>
              <div className="breadcrumb-name">{filesData.find((el) => el.scopeId === selectedFile)?.fileName}</div>
            </div>
          </div>

          <div className="pdf-view-scroll-wrapper">{<DocumentsViewer id={selectedFile} sheetType={sheetType} />}</div>
        </>
      )}

      {selectedFile && openConfirm && (
        <Confirm
          setSheetType={(type) => {
            setSheetType(type);
            setOpenConfirm(false);
          }}
          cancel={() => {
            setOpenConfirm(false), setSelectedFile("");
          }}
        />
      )}
    </div>
  );
};

export default DocumentView;
